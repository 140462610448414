<template>
  <div id="app">
    <div v-if="haveuser" class="flex max-h-screen overflow-y-hidden">
        <aside :class="{ 'transform -translate-x-full':!showsidebar, 'md:relative md:w-96':showsidebar }" class="min-h-screen bg-black text-white w-96 absolute shadow-sm transition ease-in-out duration-200 z-50">
            <div class="flex justify-between absolute bg-black w-full border-b border-white">
                <div class="flex items-center">
                    <div class="w-10 h-10 bg-white rounded-full text-black flex items-center justify-center mx-5 my-5">
                        <span class=" text-2xl font-bold">{{ (UserName != null ? UserName.substring(0,1) : null) }}</span>
                    </div>
                    <span class=" uppercase text-lg">{{ (UserName != null ? UserName : null) }}</span>
                </div>
                <button @click="closesidebar()" class="mx-5">
                    <SvgIcon :iconname="'x-mark'"></SvgIcon>
                </button>
            </div>
            <div class="mt-20 overflow-y-scroll h-full">
               
               
                <router-link v-if="access.DASHBOARD"  to="/" :class="{ 'bg-gray-600':$route.path==='/' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'chart-bar'"></SvgIcon>
                    <span>DASHBOARD</span>
                </router-link>


                <div v-if="access.PURCHASEREQUEST || access.PURCHASEORDER || access.RECEIVING || access.TRANSFERIN || access.TRANSFEROUT || access.PHYSICALCOUNTAPPROVER || access.PHYSICALCOUNTAPPROVER || access.PRFIRSTAPPROVAL || access.PRFINALAPPROVAL || access.BLANKETPO || access.STOCKREQUEST" class="text-gray-500 font-semibold text-sm m-3">INVENTORY</div>
                <router-link  v-if="access.PURCHASEREQUEST"  to="/purchaserequest" :class="{ 'bg-gray-600':$route.path==='/purchaserequest' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'shopping-cart'"></SvgIcon>
                    <span>PURCHASE REQUEST</span>
                </router-link>
                <div v-if="IsApprover">
                    <router-link  v-if="access.PRFIRSTAPPROVAL" to="/prapproval" :class="{ 'bg-gray-600':$route.path==='/prapproval' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                        <SvgIcon :iconname="'file-check'"></SvgIcon>
                        <span>PR APPROVAL</span>
                    </router-link>
                </div>
                <div v-if="IsApprover">
                    <router-link v-if="access.PRFINALAPPROVAL" to="/prfinalapproval" :class="{ 'bg-gray-600':$route.path==='/prfinalapproval' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                        <SvgIcon :iconname="'badge-check'"></SvgIcon>
                        <span>CMD PR APPROVAL</span>
                    </router-link>
                </div>
                <router-link  v-if="access.PURCHASEORDER"  to="/purchaseorder" :class="{ 'bg-gray-600':$route.path==='/purchaseorder' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'folder'"></SvgIcon>
                    <span>PURCHASE ORDER</span>
                </router-link>

                <router-link v-if="access.BLANKETPO" to="/blanketpo" :class="{ 'bg-gray-600':$route.path==='/blanketpo' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'folder-open'"></SvgIcon>
                    <span>BLANKET PO</span>
                </router-link>

                <router-link v-if="access.RECEIVING"  to="/receiving" :class="{ 'bg-gray-600':$route.path==='/receiving' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'archive-box-arrow-down'"></SvgIcon>
                    <span>RECEIVING</span>
                </router-link>

                <router-link v-if="access.STOCKREQUEST"  to="/stockrequest" :class="{ 'bg-gray-600':$route.path==='/stockrequest' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'arrow-up-tray'"></SvgIcon>
                    <span>STOCK REQUEST</span>
                </router-link>


                <router-link v-if="access.STOCKREQUESTAPPROVAL" to="/stockrequestapproval" :class="{ 'bg-gray-600':$route.path==='/stockrequestapproval' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'arrow-up-tray'"></SvgIcon>
                    <span>STOCK REQUEST APPROVAL</span>
                </router-link>

                
                <router-link v-if="access.CMDSTOCKREQUESTTRANSFEROUTAPPROVAL" to="/cmdstockrequestapproval" :class="{ 'bg-gray-600':$route.path==='/cmdstockrequestapproval' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'arrow-up-tray'"></SvgIcon>
                    <span>CMD STOCK REQUEST/TRANSFER OUT APPROVAL</span>
                </router-link>


                <router-link v-if="access.TRANSFEROUT"  to="/transferout" :class="{ 'bg-gray-600':$route.path==='/transferout' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'arrow-up-tray'"></SvgIcon>
                    <span>TRANSFER OUT</span>
                </router-link>

                <!-- <router-link v-if="access.TRANSFEROUTAPPROVAL"  to="/transferoutapproval" :class="{ 'bg-gray-600':$route.path==='/transferoutapproval' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'thumbs-up'"></SvgIcon>
                    <span>TRANSFER OUT APPROVAL</span>
                </router-link> -->

                <router-link v-if="access.TRANSFERIN"  to="/transferin" :class="{ 'bg-gray-600':$route.path==='/transferin' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'arrow-down-tray'"></SvgIcon>
                    <span>TRANSFER IN</span>
                </router-link>
              

                <router-link v-if="access.PHYSICALCOUNT"  to="/physicalcount" :class="{ 'bg-gray-600':$route.path==='/physicalcount' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'document-check'"></SvgIcon>
                    <span>PHYSICAL COUNT</span>
                </router-link>
                <router-link v-if="access.PHYSICALCOUNTAPPROVER"  to="/physicalcountapprover" :class="{ 'bg-gray-600':$route.path==='/physicalcountapprover' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'check'"></SvgIcon>
                    <span>PHYSICAL COUNT APPR.</span>
                </router-link>


                <div v-if="access.CAPEX && apptype == 'CAPEX'" class="text-gray-500 font-semibold text-sm m-3">PROJECTS</div>
                <router-link v-if="access.CAPEX && apptype == 'CAPEX'"  to="/capex" :class="{ 'bg-gray-600':$route.path==='/capex' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'currency-dollar'"></SvgIcon>
                    <span>CAPEX</span>
                </router-link>

                <div v-if="access.PURCHASESUMMARYREPORT || access.PURCHASEDETAILREPORT || access.SUPPLIERLISTREPORT || access.PRICECOMPARISONREPORT || access.PRICEMOVEMENTREPORT" class="text-gray-500 font-semibold text-sm m-3">REPORTS</div>
                
                <router-link v-if="access.PURCHASEREQUESTREPORT" to="/purchaserequestbydate" :class="{ 'bg-gray-600':$route.path==='/purchaserequestbydate' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'folder'"></SvgIcon>
                    <span>PURCHASE REQUEST REPORT</span>
                </router-link>

                <router-link v-if="access.PURCHASEORDERSREPORTS" to="/purchaseorderbydate" :class="{ 'bg-gray-600':$route.path==='/purchaseorderbydate' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'folder'"></SvgIcon>
                    <span>PURCHASE ORDERS REPORT</span>
                </router-link>

                <router-link v-if="access.TRANSFEROUTREPORT" to="/transferoutreportbydate" :class="{ 'bg-gray-600':$route.path==='/transferoutreportbydate' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'folder'"></SvgIcon>
                    <span>TRANSFER OUT REPORT</span>
                </router-link>
                

                <router-link v-if="access.PURCHASESUMMARYREPORT"  to="/purchasesummary" :class="{ 'bg-gray-600':$route.path==='/purchasesummary' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'folder'"></SvgIcon>
                    <span>PURCHASE SUMMARY</span>
                </router-link>
                <router-link v-if="access.PURCHASEDETAILREPORT"  to="/purchasedetail" :class="{ 'bg-gray-600':$route.path==='/purchasedetail' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'folder'"></SvgIcon>
                    <span>PURCHASE DETAIL</span>
                </router-link>
                <router-link v-if="access.SUPPLIERLISTREPORT"  to="/supplierlist" :class="{ 'bg-gray-600':$route.path==='/supplierlist' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'folder'"></SvgIcon>
                    <span>SUPPLIER LIST</span>
                </router-link>
                <router-link v-if="access.PRICECOMPARISONREPORT"  to="/pricecomparison" :class="{ 'bg-gray-600':$route.path==='/pricecomparison' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'folder'"></SvgIcon>
                    <span>PRICE COMPARISON</span>
                </router-link>
                <router-link v-if="access.PRICEMOVEMENTREPORT"  to="/pricemovement" :class="{ 'bg-gray-600':$route.path==='/pricemovement' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'folder'"></SvgIcon>
                    <span>PRICE MOVEMENT</span>
                </router-link>


                <div v-if="access.SUPPLIERS || access.SUPPLIERSPRICE || access.DEPARTMENT || access.INVENTORYITEM || access.GROUPS || access.CATEGORY || access.LOCATION || access.AREA || access.UOM || access.GLACCOUNT" class="text-gray-500 font-semibold text-sm m-3">MAINTENANCE</div>
                <router-link v-if="access.SUPPLIERS"  to="/supplier" :class="{ 'bg-gray-600':$route.path==='/supplier' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'building-office-2'"></SvgIcon>
                    <span>SUPPLIERS</span>
                </router-link>
                <router-link v-if="access.SUPPLIERSPRICE"  to="/supplierprice" :class="{ 'bg-gray-600':$route.path==='/supplierprice' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'arrow-trending-up'"></SvgIcon>
                    <span>SUPPLIERS PRICE</span>
                </router-link>
                <router-link v-if="access.DEPARTMENT"  to="/department" :class="{ 'bg-gray-600':$route.path==='/department' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'user-group'"></SvgIcon>
                    <span>DEPARTMENT</span>
                </router-link>
                <router-link v-if="access.INVENTORYITEM"  to="/inventory" :class="{ 'bg-gray-600':$route.path==='/inventory' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'tag'"></SvgIcon>
                    <span>INVENTORY ITEM</span>
                </router-link>
                <router-link v-if="access.GROUPS"  to="/group" :class="{ 'bg-gray-600':$route.path==='/group' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'folder-arrow-down'"></SvgIcon>
                    <span>GROUPS</span>
                </router-link>
                <router-link v-if="access.CATEGORY"  to="/category" :class="{ 'bg-gray-600':$route.path==='/category' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'rectangle-group'"></SvgIcon>
                    <span>CATEGORY</span>
                </router-link>
                <router-link v-if="access.LOCATION"  to="/location" :class="{ 'bg-gray-600':$route.path==='/location' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'map-pin'"></SvgIcon>
                    <span>LOCATION</span>
                </router-link>
                <router-link v-if="access.AREA"  to="/area" :class="{ 'bg-gray-600':$route.path==='/area' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'map-pin-alt'"></SvgIcon>
                    <span>AREA</span>
                </router-link>
                <router-link v-if="access.UOM"  to="/unitofmeasure" :class="{ 'bg-gray-600':$route.path==='/unitofmeasure' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'inbox-alt'"></SvgIcon>
                    <span>UoM</span>
                </router-link>
                <router-link v-if="access.GLACCOUNT"  to="/glaccount" :class="{ 'bg-gray-600':$route.path==='/glaccount' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'book'"></SvgIcon>
                    <span>GL ACCOUNT</span>
                </router-link>



                <div v-if=" access.ROLES || access.USERS || access.APPS" class="text-gray-500 font-semibold text-sm m-3">SETTINGS</div>
                <router-link v-if="access.APPS" to="/appsname" :class="{ 'bg-gray-600':$route.path==='/appsname' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'x-company'"></SvgIcon>
                    <span>APPS</span>
                </router-link>
                <router-link v-if="access.ROLES"  to="/roles" :class="{ 'bg-gray-600':$route.path==='/roles' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'key'"></SvgIcon>
                    <span>ROLES</span>
                </router-link>
                <router-link v-if="access.USERS"  to="/user" :class="{ 'bg-gray-600':$route.path==='/user' }" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'user-circle'"></SvgIcon>
                    <span>USERS</span>
                </router-link>
                <div class=" mt-36"></div>
            </div>
            
            <div class="absolute inset-x-0 bottom-0 bg-black">
                <hr>
                <button @click="Logout()" class="w-full py-5 px-5 flex gap-3 hover:bg-gray-600">
                    <SvgIcon :iconname="'arrow-left-on-rectangle'"></SvgIcon>
                    <span>LOG-OUT</span>
                </button>
            </div>
        </aside>
        <main class="w-full h-screen relative overflow-hidden dark:bg-white dark:text-black">

            <div class=" w-full h-20 flex items-center absolute bg-white border-b border-black">
                <button @click="opensidebar()" class="mx-3">
                    <SvgIcon :iconname="'bars-3'"></SvgIcon>
                </button>
                <span class=" text-2xl md:mx-5 uppercase">{{ $route.name }}</span>
            </div>
            <div class="h-screen mt-20 overflow-y-scroll">
                <router-view/>
                <div class=" mt-36"></div>
            </div>
            <div class=" w-full h-20 flex items-center"></div>
            <div class="absolute inset-x-0 bottom-0 h-16 flex justify-center items-center bg-gray-100">
                <span class="mx-5 text-xl">
                    Antonio Inventory System 2023
                </span>
            </div>
            
        </main>
        <SelectApp :showModal="showselectapp" @onSelectCompany="SelectApp"></SelectApp>
    </div>
    <div v-else>
      <LoginView></LoginView>
    </div>
  </div>
    
</template>

<script>

import SvgIcon from '@/components/SvgIcon.vue'
import SelectApp from '@/components/SelectApp.vue'
import antoniomixin from '@/mixin/antoniomixin'
import LoginView from '@/components/LoginView.vue'

export default{
    mixins:[antoniomixin],
    props:{
        appsname: String
    }, 
    components:{
        SvgIcon,
        SelectApp,
        LoginView
    },
    data(){
        return{
            UserName: null,
            showsidebar: true,
            showselectapp: false,
            closesidebarclass: "",
            IsApprover: false,
            haveuser: true,
            apptype: localStorage.type
        }
    },
    mounted(){
        this.getcurrentuser()
    },
    methods:{
        closesidebar(){
            this.showsidebar=false
        },
        opensidebar(){
            this.showsidebar=true
        },
        Logout(){
            localStorage.removeItem("name");
            localStorage.removeItem("token");
            localStorage.removeItem("appid")
            localStorage.removeItem("departmentId")
            localStorage.removeItem("user")
            localStorage.removeItem("storeid")
            this.AppId = 0
            this.$router.push("/")
            window.location.reload()
        },
        SelectApp(appid){
            if(appid!=0){
                //this.showselectapp = false
                window.location.reload()
            }
        },
        async getcurrentuser(){
            await this.axios.get("User/get-currentuser").then((response) => {
                if(response.data.error == false){
                    this.UserName = localStorage.name
                    if(response.data.user == null){
                        this.haveuser = false
                    }else{
                      this.haveuser = true
                      if(localStorage.getItem("appid") ==  0){
                          this.showselectapp = true
                      }
                    }
                }
            }).finally(() => {
              if(this.haveuser == true){
                this.checkifapprover()
              }
            })
        },
        async checkifapprover(){

            await this.axios.get("Departments/get-departmentbyid/" + localStorage.departmentId).then((response) => {
                if(response.data.error == false){
                    if(response.data.department.approver1 == localStorage.user){
                        this.IsApprover = true
                    }
                    if(response.data.department.approver2 == localStorage.user){
                        this.IsApprover = true
                    }
                    if(response.data.department.approver3 == localStorage.user){
                        this.IsApprover = true
                    }
                    if(response.data.department.approver4 == localStorage.user){
                        this.IsApprover = true
                    }
                    if(response.data.department.approver5 == localStorage.user){
                        this.IsApprover = true
                    }
                }
            })
        },

    }
}
</script>