<template>
    <BlankLayout>
        <div class="flex">
            <div class=" h-screen bg-black hidden md:block md:w-2/3"></div>
            <div class=" h-screen flex items-center justify-between w-full md:w-1/3">
                <div class="w-full md:mx-20">
                    <ValidationView v-slot="{validated, isvalid}" :inputs="JSON.stringify(credential)" :vinputs="JSON.stringify(vcredential)">
                        <InputTemplate v-slot="{textbox, label, button, validation}">
                            <div class="mb-5 mx-5">
                                <label :class="label">Username</label>
                                <input v-model="credential.Username" :class="textbox" />
                                <span v-if="validated != null && validated.Username.message1 == 'empty'" :class="validation">&nbsp; required field</span>
                            </div>

                            <div class="mb-5 mx-5">
                                <label :class="label">Password</label>
                                <input v-model="credential.Password" type="password" :class="textbox" />
                                <span v-if="validated != null && validated.Password.message1 == 'empty'" :class="validation">&nbsp; required field</span>
                            </div>

                            <div class="mt-5 mb-5 mx-5">
                                <button @click="Login()" :disabled="isvalid==false" class="bg-blue-500 w-full" :class="button">Login</button>
                            </div>
                        </InputTemplate>
                    </ValidationView>
                </div>
            </div>
        </div>
    </BlankLayout>
</template>

<script>


import BlankLayout from '@/layout/BlankLayout.vue'
import InputTemplate from '@/components/InputTemplate.vue'
import ValidationView from '@/components/ValidationView.vue'
import antoniomixin from '@/mixin/antoniomixin'
export default {
    mixins:[antoniomixin],
    components: {
        InputTemplate,
        BlankLayout,
        ValidationView
    },
    data(){
        return{
            credential:{
                Username: null,
                Password: null
            },
            vcredential:{
                Username: { required: true, message1: null , error: false },
                Password: { required: true, message1: null , error: false }
            },
        }
    },
    methods:{
        async Login(){
            await this.axios.get("User/login", {params: this.credential}).then((response) =>{
                if(response.data.error == false){
                    localStorage.token = response.data.token
                    localStorage.name = response.data.firstName + " " + response.data.lastName
                    localStorage.departmentId = response.data.departmentid
                    localStorage.user = response.data.user
                    localStorage.appid = 0
                    localStorage.department = response.data.department
                    window.location.reload()
                }
            })
        }
    }
}
</script>