<template>

      <div class="p-5">
        <LoadQuery v-slot="{data}" :query="'User/get-dashboarddata/' + appid" :variables="null">
          <div v-if="data">

            <div class="flex gap-2 mb-10">
              <div class="bg-red-500 w-full h-40 rounded-xl shadow-md flex flex-col justify-center items-center text-white">
                <span class="text-xl">TOTAL PRODUCTS</span>
                <span class="text-5xl">{{ data.totalproduct }}</span>
              </div>
              <div class="bg-yellow-500 w-full h-40 rounded-xl shadow-md flex flex-col justify-center items-center text-white">
                <span class="text-xl">LOW STOCK PRODUCTS</span>
                <span class="text-5xl">{{ data.lowstockproduct }}</span>
              </div>
              <div class="bg-gray-500 w-full h-40 rounded-xl shadow-md flex flex-col justify-center items-center text-white">
                <span class="text-xl">OUT OF STOCK PRODUCTS</span>
                <span class="text-5xl">{{ data.outofstockproduct }}</span>
              </div>
              <div class="bg-blue-500 w-full h-40 rounded-xl shadow-md flex flex-col justify-center items-center text-white">
                <span class="text-xl">MOST STOCK PRODUCTS</span>
                <span class="text-5xl">{{ data.moststockproduct }}</span>
              </div>
            </div>
            <div class="bg-red-100 rounded-xl shadow-md">
              <div class="p-2">
                <span class="text-xl font-bold">Recent Purchase Order</span>
              </div>
              <div class="mb-10">
                <div class=" border-2 border-red-100 h-96 overflow-y-scroll">
                  <TableTemplate v-slot="{table, thead, th, tr, td, badgegreen, badgered}">
                    <table :class="table">
                      <thead :class="thead">
                        <th :class="th">Date Created</th>
                        <th :class="th">Document No.</th>
                        <th :class="th">Department</th>
                        <th :class="th">Status</th>
                      </thead>
                      <tbody>
                        <tr v-for="item in data.recentpo" :key="item.lineId" :class="tr">
                          <td :class="td">{{ formatshortdate(item.createdAt) }}</td>
                          <td :class="td">{{ item.documentNo }}</td>
                          <td :class="td">{{ item.departmentName }}</td>
                          <td :class="td">
                              <span v-if="item.isClosed == 1" :class="badgegreen">OPEN</span>
                              <span v-else :class="badgered">CLOSED</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </TableTemplate>
                </div>
              </div>
            </div>
            <div class="bg-red-100 rounded-xl shadow-md">
              <div class="p-2">
                <span class="text-xl mb-5 font-bold">Top 5 Most Purchase Items</span>
              </div>
              <div>
                <div class=" border-2 border-red-100 h-96 overflow-y-scroll">
                  <TableTemplate v-slot="{table, thead, th, tr, td}">
                    <table :class="table">
                      <thead :class="thead">
                        <th :class="th">Inventory Code</th>
                        <th :class="th">Inventory Name</th>
                        <th :class="th">Category</th>
                        <th :class="th">Quantity</th>
                        <th :class="th">Cost</th>
                        <th :class="th">Total</th>
                      </thead>
                      <tbody>
                        <tr v-for="item in data.toppurchaseitem" :key="item.inventoryCode" :class="tr">
                          <td :class="td">{{ item.inventoryCode }}</td>
                          <td :class="td">{{ item.inventoryName }}</td>
                          <td :class="td">{{ item.category }}</td>
                          <td :class="td">{{ item.quantity|currencyformat }}</td>
                          <td :class="td">{{ item.cost|currencyformat }}</td>
                          <td :class="td">{{ item.total|currencyformat }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </TableTemplate>
                </div>
              </div>
            </div>
            
          
          </div>
        
        </LoadQuery>
      </div>
</template>

<script>

import TableTemplate from '@/components/TableTemplate.vue';
import LoadQuery from '@/components/LoadQuery.vue';
import antoniomixin from '@/mixin/antoniomixin';

export default {
  mixins:[antoniomixin],
  components: {
    TableTemplate,
    LoadQuery
  },
  data(){
    return{
      haveuser: false,
      appid: 0
    }
  },
  mounted(){
    if(this.AppId > 0){
      this.appid = this.AppId
    }
  }
}
</script>

