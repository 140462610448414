import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'DashBoard',
    component: HomeView
  },
  {
    path: '/roles',
    name: 'Roles',
    component: () => import('../views/RoleView.vue')
  },
  {
    path: '/user',
    name: 'Users',
    component: () => import('../views/UserView.vue')
  },
  {
    path: '/inventory',
    name: 'Inventory Items',
    component: () => import('../views/InventoryView.vue')
  },
  {
    path: '/supplier',
    name: 'Suppliers',
    component: () => import('../views/SupplierView.vue')
  },
  {
    path: '/group',
    name: 'Inventory Groups',
    component: () => import('../views/GroupsView.vue')
  },
  {
    path: '/category',
    name: 'Inventory Categories',
    component: () => import('../views/CategoryView.vue')
  },
  {
    path: '/location',
    name: 'Inventory Locations',
    component: () => import('../views/LocationView.vue')
  },
  {
    path: '/purchaserequest',
    name: 'Purchase Request',
    component: () => import('../views/PurchaseRequestView.vue')
  },
  {
    path: '/purchaseorder',
    name: 'Purchase Orders',
    component: () => import('../views/PurchaseOrderView.vue')
  },
  {
    path: '/receiving',
    name: 'Receiving',
    component: () => import('../views/ReceivingView.vue')
  },
  {
    path: '/transferin',
    name: 'Transfer In',
    component: () => import('../views/TransferInView.vue')
  },
  {
    path: '/transferout',
    name: 'Transfer Out',
    component: () => import('../views/TransferOutView.vue')
  },
  {
    path: '/physicalcount',
    name: 'Physical Count',
    component: () => import('../views/PhysicalCountView.vue')
  },
  {
    path: '/physicalcountapprover',
    name: 'Physical Count Approval',
    component: () => import('../views/PhysicalCountApprove.vue')
  },
  {
    path: '/department',
    name: 'Departments',
    component: () => import('../views/DepartmentView.vue')
  },
  {
    path: '/supplierprice',
    name: 'Supplier Price',
    component: () => import('../views/SupplierPriceView.vue')
  },
  {
    path: '/capex',
    name: 'CAPEX',
    component: () => import('../views/CapexView.vue')
  },
  {
    path: '/purchasesummary',
    name: 'Purchase Summary',
    component: () => import('../views/reports/PurchaseSummaryReportView.vue')
  },
  {
    path: '/purchasedetail',
    name: 'Purchase Detail',
    component: () => import('../views/reports/PurchaseDetailReportView.vue')
  },
  {
    path: '/supplierlist',
    name: 'Supplier List',
    component: () => import('../views/reports/SupplierListReportView.vue')
  },
  {
    path: '/pricecomparison',
    name: 'Price Comparison',
    component: () => import('../views/reports/PriceComparisonReportView.vue')
  },
  {
    path: '/pricemovement',
    name: 'Price Movement',
    component: () => import('../views/reports/PriceMovementReportView.vue')
  },
  {
    path: '/prapproval',
    name: 'PR First Approval',
    component: () => import('../views/PRFirstApproval.vue')
  },
  {
    path: '/prfinalapproval',
    name: 'PR Final Approval',
    component: () => import('../views/PRFinalApproval.vue')
  },
  {
    path: '/appsname',
    name: 'Apps Name',
    component: () => import('../views/AppsNameView.vue')
  },
  {
    path: '/blanketpo',
    name: 'Blanket PO',
    component: () => import('../views/BlanketPurchaseOrderView.vue')
  },
  {
    path: '/area',
    name: 'Area',
    component: () => import('../views/AreaView.vue')
  },
  {
    path: '/glaccount',
    name: 'GL Accounts',
    component: () => import('../views/GLAccountView.vue')
  },
  {
    path: '/unitofmeasure',
    name: 'Unit of Measures',
    component: () => import('../views/UnitOfMeasureView.vue')
  },
  {
    path: '/transferoutapproval',
    name: 'Transfer Out Approval',
    component: () => import('../views/TransferOutApprovalView.vue')
  },
  {
    path: '/stockrequest',
    name: 'Stock Request',
    component: () => import('../views/StockRequestView.vue')
  },
  {
    path: '/stockrequestapproval',
    name: 'Stock Request Approval',
    component: () => import('../views/StockRequestApproval.vue')
  },
  {
    path: '/cmdstockrequestapproval',
    name: 'CMD STOCK REQUEST / TRANSFER OUT APPROVAL',
    component: () => import('../views/CmdStockRequestApproval.vue')
  },
  {
    path: '/purchaseorderbydate',
    name: 'Purchase Orders Report',
    component: () => import('../views/reports/PurchaseOrderByDateReportView.vue')
  },
  {
    path: '/purchaserequestbydate',
    name: 'Purchase Request Report',
    component: () => import('../views/reports/PurchaseRequestReportView.vue')
  },
  {
    path: '/transferoutreportbydate',
    name: 'Transfer Out Report',
    component: () => import('../views/reports/TransferOutReportView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
